import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store"

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/loginOut',
        name: 'loginOut',
        component: () => import('@/views/LoginOut.vue')
    },
    {
        path: '/changePasswordSuccess',
        name: 'changePasswordSuccess',
        component: () => import('@/views/ChangePasswordSuccess.vue')
    },
    {
        path: '/error/:code?',
        name: 'error',
        component: () => import ('@/views/Error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => {
            return {path: '/error/404'}
        },
    },
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Index.vue'),
        redirect: () => {
            return { path: '/home' }
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home/Index.vue')
            },
            {
                path: '/dict',
                name: 'dict',
                component: () => import('@/views/dict/Index.vue')
            },
            {
                path: '/log',
                name: 'log',
                component: () => import('@/views/log/Index.vue')
            },
            {
                path: '/category/:type',
                name: 'category',
                component: () => import('@/views/category/Index.vue')
            },
            {
                path: '/serve',
                name: 'serve',
                component: () => import('@/views/serve/Index.vue')
            },
            {
                path: '/serve/order',
                name: 'serveOrder',
                component: () => import('@/views/serve/Order.vue')
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/news/Index.vue')
            },
            {
                path: '/banner',
                name: 'banner',
                component: () => import('@/views/banner/Index.vue')
            },
            {
                path: '/member',
                name: 'memberIndex',
                component: () => import('@/views/member/Index.vue')
            },
            {
                path: '/member/apply',
                name: 'memberApply',
                component: () => import('@/views/member/Apply.vue')
            },
            {
                path: '/member/level',
                name: 'memberLevel',
                component: () => import('@/views/member/Level.vue')
            },
            {
                path: '/member/pay',
                name: 'memberPay',
                component: () => import('@/views/member/Pay.vue')
            },
            {
                path: '/member/user',
                name: 'memberUser',
                component: () => import('@/views/member/User.vue')
            },
            {
                path: '/about',
                name: 'about',
                component: () => import('@/views/about/Index.vue')
            },
            {
                path: '/admin',
                name: 'admin',
                component: () => import('@/views/admin/Index.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

//无需登录即可访问的路由名称
const noNeedLogin  = [
    'login', 'loginOut', 'changePasswordSuccess', 'error', 'index'
]

//需要登录但无需认证的路由名称
// const noNeedAuth = [
//     'home'
// ]

router.beforeEach( async to => {
    await store.dispatch('keepLogin')
    if (noNeedLogin.indexOf(to.name) === -1 && !store.state.login) return { name: 'login' }
})
export default router
