import axios from "axios"
import router from "@/router";
import store from "@/store";

const DOMAIN = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/' : 'https://dyysxh.domstudio.net/'
const request = function request(config) {
    config.method = config.method ?? 'post';
    const instance = axios.create({
        baseURL: DOMAIN + 'index.php/',
        timeout: config.timeout ?? 60000
    });
    //请求拦截
    instance.interceptors.request.use(config => {
        if (store.state.login) config.headers.Authorization = store.state.accessToken
        return config;
    }, error => {
        return Promise.reject(error);
    });
    //响应拦截
    instance.interceptors.response.use(response => {
        if (response.data.error === 0) {
            return response.data.data
        } else {
            return Promise.reject(response.data.message)
        }
    }, async error => {
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.data.error === 4014) {
                    const res = await axios.post(
                        error.config.baseURL + 'admin/refreshToken',
                        {refresh_token: store.state.refreshToken}
                    );
                    if (res?.data?.error !== 0) {
                        store.commit('loginOut')
                        return router.replace('/login')
                    }
                    store.commit('loginRefresh', res.data.data)
                    return request(error.config)
                } else {
                    store.commit('loginOut')
                    return router.replace('/login')
                }
            } else {
                return Promise.reject(error.response.data.message);
            }
        } else {
            return Promise.reject(error.message);
        }
    });
    return instance(config)
}

export default {request, domain: DOMAIN}
