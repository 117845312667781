import {createStore} from 'vuex'

export default createStore({
    state: {
        login: false,
        accessToken: '',
        refreshToken: ''
    },
    getters: {
        user(state) {
            if (state.accessToken) {
                return JSON.parse(window.atob(state.accessToken.split(' ')[1].split('.')[1])).data
            }
            return null
        }
    },
    mutations: {
        loginIn(state, token) {
            state.login = true
            state.accessToken = token.accessToken
            state.refreshToken = token.refreshToken
        },
        loginOut(state) {
            state.login = false
            state.accessToken = ''
            state.refreshToken = ''
        },
        loginRefresh(state, accessToken) {
            state.accessToken = accessToken
        }
    },
    actions: {
        keepLogin({commit, state}) {
            const accessToken = sessionStorage.getItem('accessToken')
            const refreshToken = sessionStorage.getItem('refreshToken')
            if (accessToken && refreshToken && !state.login) {
                commit('loginIn', {accessToken, refreshToken})
                sessionStorage.removeItem('accessToken')
                sessionStorage.removeItem('refreshToken')
            }
        }
    }
})

