<script setup>
import {computed, onMounted, ref, watch} from "vue";
import axios from "@/utils/axios";
import {ElMessage} from "element-plus";

const props = defineProps({
    modelValue: String,
    name: {
        type: String,
        default: 'file'
    },
    type: {
        type: String,
        default: 'image'
    },
    limit: {
        type: Number,
        default: 1
    },
    multiple: {
        type: Boolean,
        default: false
    },
    text: {
        type: String,
        default: '文件上传'
    },
})
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(newValue) {
        emit('update:modelValue', newValue)
    }
})

const fileList = ref([]); //文件列表
const hide = ref(false) //上传按钮隐藏
const previewUrl = ref('') //图片预览地址
const previewBox = ref(false) //图片预览弹窗显示
const accept = ref('') //可接受文件类型
switch (props.type) {
    case 'image':
        accept.value = '.png,.jpg,.jpeg'
        break
    default:
        accept.value = JSON.parse(JSON.stringify(props.type))
}

onMounted(() => {
    init(value.value)
})

watch(value, (newValue)=>{
    init(newValue)
})

const init = (value) => {
    const list = [];
    value && value.split(',').forEach(item => {
        item && list.push({url: item, name: item})
    })
    fileList.value = list
    hide.value = fileList.value.length >= props.limit
}

const beforeUpload = (file) => {
    const allow = accept.value.split(',')
    const ext = file.name.split('.').pop()
    if (allow.indexOf('.' + ext) === -1) {
        ElMessage.warning('文件格式不正确！')
        return false
    }
}

//上传方法
const upload = (options) => {
    const formData = new FormData();
    formData.append(options.filename, options.file)
    return axios.request({
        url: 'upload',
        data: formData
    })
}

//超出限制回调
const exceed = () => {
    ElMessage.warning('选择文件数量超出上传数量限制')
}
//成功回调
const success = (res, file) => {
    file.url = res
    hide.value = fileList.value.length >= props.limit
    value.value = getValue()
}
//失败回调
const error = (res) => {
    ElMessage.error(res)
}
//删除回调
const remove = () => {
    hide.value = fileList.value.length >= props.limit
    value.value = getValue()
}
const preview = (res) => {
    previewBox.value = true
    previewUrl.value = res.url
}

const getValue = () => {
    const list = [];
    fileList.value.forEach(item => {
        list.push(item?.url);
    })
    return list.join()
}

</script>

<template>
    <div class="cbz-upload">
        <el-upload
            v-model:file-list="fileList"
            :http-request="upload"
            :name="name"
            :list-type="type==='image' ? 'picture-card' : 'text'"
            :limit="limit"
            :multiple="multiple"
            :accept="accept"
            :before-upload="beforeUpload"
            :on-success="success"
            :on-error="error"
            :on-preview="preview"
            :on-remove="remove"
            :on-exceed="exceed"
            :class="hide ? 'hide' : ''"
        >
            <el-icon v-if="type==='image'"><component :is="'Plus'" /></el-icon>
            <el-button v-else type="primary">{{props.text}}</el-button>
        </el-upload>
        <el-image-viewer v-if="previewBox" :url-list="[previewUrl]" @close="previewBox=false" />
    </div>
</template>

<style scoped lang="scss">
    :deep(.el-upload--picture-card) {
        --el-upload-picture-card-size: 100px;
    }
    :deep(.el-upload-list--picture-card) {
        --el-upload-list-picture-card-size: 100px;
    }
    :deep(.hide .el-upload--picture-card) {
        display: none;
    }
    :deep(.hide .el-button) {
        display: none!important;
    }
    :deep(.el-icon--close-tip) {
        display: none!important;
    }
    img {
        object-fit: cover;
    }
</style>