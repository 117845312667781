<script setup>
import axios from "@/utils/axios";
import {computed, reactive, ref, watch} from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'

const props = defineProps({
    config: {
        type: Object,
        required: true,
    }
})

const config = computed(() => {
    return {
        url: props.config?.url ?? '',
        delete: props.config?.delete ?? '',
        export: props.config?.export ?? '',
        import: props.config?.import ?? '',
        template: props.config?.template ?? '',
        where: props.config?.where ?? {},
        operate: props.config?.operate ?? ['refresh', 'search'],
        fields: props.config?.fields ?? [],
        page: props.config?.page ?? 1,
        limit: props.config?.limit ?? 10,
        limits: props.config?.limits ?? [10, 20, 30, 40, 50, 100, 500, 1000],
        saveDialog: props.config?.saveDialog ?? '50%',
        save: props.config?.save ?? {}
    }
})

//组件内部数据
const data = reactive({
    data: [],
    total: 0,
    error: '',
    search: false,
    save: false,
    tableLoading: false,
    formLoading: false
})

//获取数据列表
const getList = () => {
    data.tableLoading = true
    const where = {...config.value.where}
    if (config.value.page) {
        where.page = config.value.page
        where.limit = config.value.limit
    }
    axios.request({
        url: config.value.url,
        data: where
    }).then(res => {
        data.tableLoading = false
        data.data = res.lists
        data.total = res.total
    }).catch(err => {
        data.tableLoading = false
        data.error = err
    })
}

getList();

watch(
    () => config.value.where,
    () => {
    getList()
}, {deep: true})

//分页大小变化
const onSizeChange = (number) => {
    config.value.limit = number
    getList()
}
//页码变化
const onCurrentChange = (number) => {
    config.value.page = number
    getList()
}

//执行搜索
const searchForm = reactive({})
const onSearchSubmit = () => {
    const filter = []
    config.value.fields.forEach(item => {
        const filed = item?.field
        if (searchForm[filed] !== undefined && searchForm[filed] !== null && searchForm[filed] !== '') {
            const search = item?.search ?? '='
            filter.push({field: filed, option: search, value: searchForm[filed]})
        }
    })
    config.value.where.filter = JSON.stringify(filter)
    if (config.value.page !== false) config.value.page = 1
    getList()
    data.search = false
}
//重置搜索
const onSearchReset = () => {
    Object.keys(searchForm).forEach(item => {
        searchForm[item] = '';
    })
    delete config.value.where?.filter
    config.value.page = 1
    getList()
    data.search = false
}
//刷新
const refresh = () => getList()

//删除
const cbzTableRef = ref()
const onDelete = async (row = null) => {
    const ids = [];
    if (row) {
        ids.push(row.id)
    } else {
        const rows = cbzTableRef.value.getSelectionRows()
        if (rows.length === 0) {
            ElMessage.warning('未选中任何数据！')
            return false
        }
        rows.forEach(row => { ids.push(row.id) })
    }
    try {
        await ElMessageBox.confirm('确认要删除选中的数据吗？', '删除确认', {type: 'warning'})
        data.tableLoading = true
        await axios.request({
            url: config.value.delete,
            data: { ids }
        })
        data.tableLoading = false
        ElMessage.success('删除成功！')
        getList()
    } catch (error) {
        data.tableLoading = false
        if (typeof error === 'string' && error !== 'cancel') {
            ElMessage.error(error)
        }
    }
}

const loadingDialog = reactive({status: false, text: '数据导出中，请稍后...'})
//文件下载
const download = (url, message) => {
    const createA = document.createElement('a'); //创建一个a标签
    createA.href = url; //给a标签设置href属性
    document.body.appendChild(createA);//兼容火狐
    createA.click();//给a标签绑定点击事件，通过a标签的href属性和点击事件实现下载的功能
    document.body.removeChild(createA);
    ElMessage.success(message)
}
//导出
const exportExcel = () => {
    const limit = 1500;
    ElMessageBox.confirm('确认要导出此数据吗？', '导出确认', {type: 'warning'}).then(()=>{
        loadingDialog.status = true
        let time = 0;
        const timer = setInterval(() => {
            time++;
        }, 1)
        const where = {...config.value.where}
        axios.request({
            url:  config.value.export,
            data: where,
            timeout: 0
        }).then(res => {
            clearInterval(timer)
            const delay = time < limit ? (limit - time) : 1
            setTimeout(() => {
                loadingDialog.status = false
                download(res, '导出成功')
            }, delay)
        }).catch(err => {
            clearInterval(timer)
            loadingDialog.status = false
            ElMessage.error(err)
        })
    }).catch(() => {})
}

//数据导入
const importDialog = reactive({status: false, loading: false, data: []})
const uploadRef = ref()
const onImport = reactive({
    click: () => {
        importDialog.status = true
        importDialog.data = []
    },
    change: (file) => {
        if (file.status === 'ready') {
            const ext = file.name.split('.').pop()
            if (ext !== 'xlsx') {
                ElMessage.error('只支持上传.xlsx文件')
                importDialog.data = []
                return false
            }
        }
    },
    upload: () => {
        const formData = new FormData();
        formData.append('file', importDialog.data[0].raw)
        importDialog.loading = true
        return axios.request({
            url: config.value.import,
            data: formData,
            timeout: 0
        })
    },
    submit: () => {
        if (importDialog.data.length === 0) {
            ElMessage.warning('请先上传Excel文件')
            return false
        }
        uploadRef.value.submit()
    },
    success: (res) => {
        importDialog.loading = false
        importDialog.status = false
        refresh()
        ElMessage.success('导入成功')
    },
    error: (err) => {
        importDialog.loading = false
        ElMessageBox.alert(err, '导入出错', {type: 'error'})
    },
    template: () => {
        axios.request({
            url: config.value.template,
        }).then(res => {
            download(res, '下载成功')
        }).catch(err => {
            ElMessage.error(err)
        })
    }
})

const form = ref({})
const add = () => {
    config.value.save.url = config.value.save?.add ?? ''
    if (form.value.id) delete form.value.id
    if (config.value.save?.fields && Array.isArray(config.value.save.fields)) {
        config.value.save.fields.forEach(item => form.value[item.field] = item.value ?? '')
    }
    data.save = true
}
const edit = (row) => {
    config.value.save.url = config.value.save?.edit ?? ''
    form.value.id = row.id
    if (config.value.save?.fields && Array.isArray(config.value.save.fields)) {
        config.value.save.fields.forEach(item => {
            if (item.field === 'password') {
                form.value[item.field] = ''
                item.placeholder = '不修改此项请留空'
            } else {
                form.value[item.field] = row[item.field]
            }
        })
    }
    data.save = true
}

const success = () => {
    data.save = false
    refresh()
}

//toolbar按钮显示条件
const checkOption = (row, options = []) => {
    if (options.length === 0) return true
    const result = []
    options.forEach(option => {
        switch ( option.type ) {
        case '=':
            result.push(row[option.field] === option.value)
            break
        case '!=':
            result.push(row[option.field] !== option.value)
            break
        case '>':
            result.push(row[option.field] > option.value)
            break
        case '<':
            result.push(row[option.field] < option.value)
            break
        case '>=':
            result.push(row[option.field] >= option.value)
            break
        case '<=':
            result.push(row[option.field] <= option.value)
            break
        }
    })
    return result.indexOf(false) === -1
}

//导出刷新方法
defineExpose({refresh, edit, delete: onDelete})
</script>

<template>
    <div class="container">
        <div class="loading" v-if="loadingDialog.status" v-loading="loadingDialog.status" :element-loading-text="loadingDialog.text"></div>
        <div class="cbz-table">
            <div class="cbz-table-header">
                <div class="cbz-table-header-left">
                    <template v-for="(operate, index) in config.operate" :key="index">
                        <el-button v-if="operate==='refresh'" color="#253043" icon="RefreshRight" @click="refresh">刷新</el-button>
                        <el-button v-if="operate==='add'" type="primary" icon="Plus" @click="add">新增</el-button>
                        <el-button v-if="operate==='deleteBatch'" type="danger" icon="Delete" @click="onDelete()">删除</el-button>
                        <el-button v-if="operate === 'import'" icon="Files"  color="#e03997" @click="onImport.click">导入</el-button>
                        <el-button
                            v-if="typeof operate ==='object' && operate.position === 'left'"
                            :type="operate.type"
                            :icon="operate.icon"
                            @click="$emit(operate.event, cbzTableRef.getSelectionRows())"
                        >
                            {{operate.title}}
                        </el-button>
                    </template>
                </div>
                <div class="cbz-table-header-right">
                    <template v-for="(operate, index) in config.operate" :key="index">
                        <el-button v-if="operate==='search'" type="success" icon="Search" @click="data.search = true">搜索</el-button>
                        <el-button v-if="operate==='export'" type="primary" icon="FolderOpened" @click="exportExcel">导出</el-button>
                        <el-button
                            v-if="typeof operate ==='object' && operate.position === 'right'"
                            :type="operate.type"
                            :icon="operate.icon"
                            @click="$emit(operate.event, cbzTableRef.getSelectionRows())"
                        >
                            {{operate.title}}
                        </el-button>
                    </template>
                </div>
            </div>
            <div class="cbz-table-body">
                <el-table
                    id="cbz-table"
                    ref="cbzTableRef"
                    row-key="id"
                    default-expand-all
                    v-loading="data.tableLoading"
                    element-loading-text="数据加载中..."
                    element-loading-background="rgba(255,255,255,.5)"
                    :data="data.data"
                    stripe
                    border
                    show-overflow-tooltip
                    max-height="calc(100vh - 334px)"
                >
                    <template #empty>
                        <div class="cbz-table-empty" v-if="!data.loading">
                            <div v-if="data.error">加载数据出错：{{ data.error }}</div>
                            <div v-else>暂无数据</div>
                        </div>
                    </template>
                    <el-table-column
                        v-for="(d, index) in config.fields"
                        :key="index"
                        :prop="d.field"
                        :label="d.title"
                        :width="d.width"
                        :min-width="d.minWidth"
                        :align="d.align || 'center'"
                        :type="d.type"
                        :fixed="d.fixed"
                        :resizable="d.resize"
                        :formatter="d.formatter"
                    >
                        <template v-if="d.template && data.data.length" #default="template">
                            <div class="color" v-if="d.template==='color'">
                                <span :style="{color: d.color}">{{template.row[d.field]}}</span>
                            </div>
                            <div class="icon" v-if="d.template==='icon'">
                                <component v-if="template.row[d.field]" :is="template.row[d.field]"></component>
                            </div>
                            <template v-if="d.template==='image'">
                                <el-image
                                    v-if="template.row[d.field]"
                                    :src="template.row[d.field]"
                                    :preview-src-list="[template.row[d.field]]"
                                    :preview-teleported="true"
                                    :fit="'scale-down'"
                                    style="display: block;height: 25px"
                                />
                                <span v-else>暂无</span>
                            </template>

                            <div class="button" v-if="d.template==='button'">
                                <el-button
                                    v-if="d.button && typeof d.button.value==='object'"
                                    size="small"
                                    :type="d.button.type[d.button.value.indexOf(template.row[d.field])]"
                                    :disabled="d.button.disabled ? d.button.disabled[d.button.value.indexOf(template.row[d.field])] : false"
                                    @click="$emit(d.button.event ?? '', template.row)"
                                >
                                    {{d.button.label[d.button.value.indexOf(template.row[d.field])]}}
                                </el-button>
                                <template v-else>
                                    <el-button
                                        v-if="d.button && (d.button?.value === undefined || d.button.value === template.row[d.field])"
                                        size="small"
                                        :type="d.button.type"
                                        :disabled="d.button.disabled ?? false"
                                        @click="$emit(d.button.event ?? '', template.row)"
                                    >
                                        {{d.button.label ?? template.row[d.field]}}
                                    </el-button>
                                    <span v-else>-</span>
                                </template>
                            </div>
                            <template v-if="d.template==='toolbar'">
                                <template v-for="(operate, index) in config.operate" :key="index" >
                                    <el-button v-if="operate==='edit'" type="warning" icon="Edit" size="small" @click="edit(template.row)" />
                                    <el-button v-if="operate==='delete'" type="danger" icon="Delete" size="small" @click="onDelete(template.row)" />
                                    <el-button
                                        v-if="typeof operate === 'object' && operate.position==='row' && checkOption(template.row, operate.options ?? [])"
                                        :icon="operate.icon"
                                        size="small"
                                        :type="operate.type"
                                        @click="$emit(operate.event, template.row)"
                                        :disabled="operate.disabled ?? false"
                                    >
                                        <template #default v-if="operate.title">
                                            {{operate.title}}
                                        </template>
                                    </el-button>
                                </template>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="cbz-table-footer" v-if="data.data.length">
                <template v-if="config.page">
                    <el-pagination
                        v-model:current-page="config.page"
                        v-model:page-size="config.limit"
                        :page-sizes="config.limits"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="data.total"
                        @size-change="onSizeChange"
                        @current-change="onCurrentChange"
                    />
                </template>
                <template v-else>
                    <el-pagination layout="total" :total="data.total"/>
                </template>
            </div>
        </div>
        <div class="cbz-table-search">
            <el-dialog v-model="data.search" title="搜索" append-to-body>
                <el-form :model="searchForm" label-position="top">
                    <el-row :gutter="32">
                        <template v-for="(d, index) in config.fields" :key="index">
                            <el-col :span="8" v-if="d.field && d.search !== false">
                                <el-form-item v-if="d.search===undefined || d.search==='=' || d.search==='like'" :label="d.title">
                                    <el-input v-model="searchForm[d.field]" clearable autocomplete="off" :placeholder="'请输入' + d.title + (d.search === 'like' ? '（模糊查询）' : '')" />
                                </el-form-item>
                                <el-form-item v-if="d.search==='select'" :label="d.title">
                                    <el-select v-model="searchForm[d.field]" clearable :placeholder="'请选择' + d.title">
                                        <el-option
                                            v-for="item in (d.options || [])"
                                            :key="d?.valueKey ? item[d.valueKey] : item.value"
                                            :label="d?.labelKey ? item[d.labelKey] : item.label"
                                            :value="d?.valueKey ? item[d.valueKey] : item.value"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="d.search === 'date'" :label="d.title">
                                    <el-date-picker
                                        v-model="searchForm[d.field]"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                    />
                                </el-form-item>
                                <el-form-item v-if="d.search === 'datetime'" :label="d.title">
                                    <el-date-picker
                                        v-model="searchForm[d.field]"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                    />
                                </el-form-item>
                            </el-col>
                        </template>
                    </el-row>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" plain native-type="reset" @click="onSearchReset">重置</el-button>
                        <el-button type="primary" @click="onSearchSubmit">确认</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <div class="cbz-table-form">
            <el-dialog v-model="data.save" :title="form?.id ? '编辑' : '新增'" :width="config.saveDialog" append-to-body>
                <cbz-form v-if="data.save" :config="config.save" v-model="form" @success="success" />
            </el-dialog>
        </div>
        <el-dialog title="导入数据" v-model="importDialog.status" width="600">
            <div class="import-box">
                <div class="item">
                    <div class="text">第一步：点击【下载模板文件】按钮下载导入数据所需的Excel模板文件</div>
                    <div class="btn"><el-button type="primary" size="small" @click="onImport.template">下载模板文件</el-button></div>
                </div>
                <div class="item">第二步：在下载好的Excel模板文件中按格式填入需要导入的数据列表并保存</div>
                <div class="item">第三步：点击【上传数据文件】按钮上传上一步中保存好的Excel文件</div>
                <div class="item">第四步：点击右下角【确认导入】按钮</div>
                <div class="btn">
                    <el-upload
                        ref="uploadRef"
                        v-model:file-list="importDialog.data"
                        :http-request="onImport.upload"
                        :auto-upload="false"
                        accept=".xlsx"
                        :limit="1"
                        :on-change="onImport.change"
                        :on-error="onImport.error"
                        :on-success="onImport.success"
                    >
                        <el-button type="primary" :disabled="!!importDialog.data.length">上传数据文件</el-button>
                    </el-upload>
                </div>
            </div>
            <template #footer>
                <el-button type="primary" @click="onImport.submit" :loading="importDialog.loading">确认导入</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped lang="scss">
.cbz-table {
    --header-bg-color: #F2F6FC;
    .cbz-table-header {
        background-color: var(--header-bg-color);
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cbz-table-header-left, .cbz-table-header-right {
            display: flex;
            align-items: center;
        }
    }
    .cbz-table-body {
        .el-table {
            --el-table-header-bg-color: var(--header-bg-color);
        }
    }
    .cbz-table-footer {
        background-color: var(--header-bg-color);
        padding: 10px;
    }
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.el-select {
    width: 100%;
}

:deep(.el-table-column--selection>.cell) {
    justify-content: center
}

:deep(.cbz-form-btn ) {
    text-align: right;
    margin-top: 64px;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.import-box {
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .text {
            margin-right: 20px;
        }
    }
}
</style>