<script setup>
import {computed, onBeforeUnmount, onMounted, ref, shallowRef} from "vue";
import axios from "@/utils/axios";
import {ElMessage} from "element-plus";
import CbzUpload from "@/components/CbzUpload.vue";
import CbzCaptcha from "@/components/CbzCaptcha.vue";
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import store from "@/store";

const props = defineProps({
    config: {
        type: Object,
    },
    modelValue: {
        type: Object,
        required: true,
    },
})
const config = computed(() => {
    return {
        url: props.config?.url ?? '',
        fields: props.config?.fields ?? [],
        rules: props.config?.rules ?? {},
        button: props.config?.button ??  {class: 'cbz-form-btn', type: 'primary', size: 'default', text: '确认提交'},
        message: props.config?.message ?? {success: '操作成功', error: '操作失败'},
        inline: props.config?.inline ?? false,
        labelPosition: props.config?.labelPosition ?? 'right',
        labelWidth: props.config?.labelWidth ?? '',
        labelSuffix: props.config?.labelSuffix ?? '',
        hideRequiredAsterisk: props.config?.hideRequiredAsterisk ?? false,
        requireAsteriskPosition: props.config?.requireAsteriskPosition ?? 'left',
        showMessage: props.config?.showMessage ?? true,
        inlineMessage: props.config?.inlineMessage ?? false,
        statusIcon: props.config?.statusIcon ?? false,
        validateOnRuleChange: props.config?.validateOnRuleChange ?? true,
        size: props.config?.size ?? '',
        disabled: props.config?.config ?? false,
        scrollToError: props.config?.scrollToError ?? false
    }
})
const emit = defineEmits(['update:modelValue', 'success', 'error'])
const data = computed({
    get() {
        return new Proxy(props.modelValue, {
            get(target, key) {
                return Reflect.get(target, key)
            },
            set(target, key, value) {
                emit('update:modelValue', {
                    ...target,
                    [key]: value
                })
                return true
            }
        })
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

const formRef = ref()
const captchaRef = ref()
const loading = ref(false)
const hasCaptcha = ref(false)
const submit = () => {
    formRef.value.validate().then(()=>{
        loading.value = true
        if (config.value.fields.find(item => item.type === 'captcha') !== undefined) {
            hasCaptcha.value = true
        }
        const params = {...data.value}
        if (hasCaptcha.value) params.captchaKey = captchaRef.value[0].getKey()
        axios.request({
            url: config.value.url,
            data: params
        }).then(res => {
            loading.value = false
            ElMessage.success(config.value.message.success ?? '操作成功')
            emit('success', res)
            if (hasCaptcha.value) captchaRef.value[0].refresh()
        }).catch(err => {
            loading.value = false
            typeof err === 'string' ? ElMessage.error(err) : ElMessage.error(config.value.message.error ?? '操作失败')
            emit('error', err)
            if (hasCaptcha.value) captchaRef.value[0].refresh()
        })
    }).catch(() => {})
}

const editorRef = shallowRef()
const toolbarConfig = {
    excludeKeys: ['group-video'],
}
const editorConfig = {
    placeholder: '请输入内容...',
    MENU_CONF: {}
}

editorConfig.MENU_CONF['uploadImage'] = {
    server: axios.domain + 'index.php/upload',
    headers: {Authorization: store.state.accessToken},
    fieldName: 'file',
    customInsert(res, insertFn) {
        console.log(res);
        insertFn(res.data, '', res.data)
    }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
})
const handleCreated = (editor) => {
    editorRef.value = editor // 记录 editor 实例，重要！
    //console.log(editor.getAllMenuKeys());
    // console.log(editor.getMenuConfig('uploadImage'));

}


</script>

<template>
    <div class="cbz-form">
        <el-form
            ref="formRef"
            :model="data"
            :rules="config.rules"
            :inline="config.inline"
            :label-position="config.labelPosition"
            :label-width="config.labelWidth"
            :label-suffix="config.labelSuffix"
            :hide-required-asterisk="config.hideRequiredAsterisk"
            :require-asterisk-position="config.requireAsteriskPosition"
            :show-message="config.showMessage"
            :inline-message="config.inlineMessage"
            :status-icon="config.statusIcon"
            :validate-on-rule-change="config.validateOnRuleChange"
            :size="config.size"
            :disabled="config.disabled"
            :scroll-to-error="config.scrollToError"
        >
            <template v-for="(item, index) in config.fields" :key="index">
                <el-form-item v-if="item.type !== 'hidden'" :label="item.title" :prop="item.field">
                    <el-input
                        v-if="!item?.type || item.type==='input'"
                        v-model="data[item.field]"
                        type="text"
                        :placeholder="item.placeholder ?? '请输入' + item.title"
                        :readonly="item.readonly ?? false"
                        :clearable="item.clearable ?? true"
                        :prefix-icon="item.icon ?? ''"
                    />
                    <el-input
                        v-if="item.type==='password'"
                        v-model="data[item.field]"
                        type="password"
                        :placeholder="item.placeholder ?? '请输入' + item.title"
                        :readonly="item.readonly ?? false"
                        :clearable="item.clearable ?? true"
                        :prefix-icon="item.icon ?? ''"
                    />
                    <el-input
                        v-if="item.type==='textarea'"
                        v-model="data[item.field]"
                        type="textarea"
                        :placeholder="item.placeholder ?? '请输入' + item.title"
                        :readonly="item.readonly ?? false"
                        :clearable="item.clearable ?? true"
                        :maxlength="item.maxlength ?? null"
                        :show-word-limit="item.showWordLimit ?? true"
                        :rows="item.rows ?? 5"
                        :autosize="item.autosize ?? false"
                        :prefix-icon="item.icon ?? ''"
                    />
                    <cbz-upload
                        v-if="item.type==='image'"
                        v-model="data[item.field]"
                        :limit="item.limit ?? 1"
                        :multiple="item.multiple ?? false"
                    />
                    <cbz-captcha
                        ref="captchaRef"
                        v-if="item.type==='captcha'"
                        v-model="data[item.field]"
                        :placeholder="item.placeholder ?? '请输入' + item.title"
                        :clearable="item.clearable ?? true"
                        :prefix-icon="item.icon ?? ''"
                    />
                    <el-input-number
                        v-if="item.type === 'number'"
                        v-model="data[item.field]"
                        :min="item.min ?? -Infinity"
                        :max="item.max ?? Infinity"
                    />
                    <el-select
                        v-if="item.type === 'select'"
                        v-model="data[item.field]"
                        :placeholder="item.placeholder ?? '请选择' + item.title"
                        :size="item.size ?? 'default'"
                        :multiple="item.multiple ?? false"
                        :clearable="item.clearable ?? true"
                        :filterable="item.filterable ?? false"
                        :value-key="item.value"
                        :disabled="item.disabled ?? false"
                    >
                        <el-option
                            v-for="option in item.options ?? []"
                            :key="item.valueKey ? option[item.valueKey] : option.value"
                            :label="item.labelKey ? option[item.labelKey] : option.label"
                            :value="item.valueKey ? option[item.valueKey] : option.value"
                        />
                    </el-select>
                    <el-radio-group
                        v-if="item.type === 'radio'"
                        v-model="data[item.field]"
                        :border="item.border ?? false"
                        :size="item.size ?? 'default'"
                    >
                        <el-radio
                            v-for="option in item.options ?? []"
                            :key="item.valueKey ? option[item.valueKey] : option.value"
                            :value="item.valueKey ? option[item.valueKey] : option.value"
                            border
                        >
                            {{item.labelKey ? option[item.labelKey] : option.label}}
                        </el-radio>
                    </el-radio-group>
                    <el-date-picker
                        v-if="item.type === 'date'"
                        v-model="data[item.field]"
                        type="date"
                        :size="item.size"
                        :clearable="item.clearable ?? true"
                        :placeholder="item.placeholder ?? '请选择' + item.title"
                    />
                    <el-date-picker
                        v-if="item.type === 'datetime'"
                        v-model="data[item.field]"
                        type="datetime"
                        :size="item.size"
                        :clearable="item.clearable ?? true"
                        :placeholder="item.placeholder ?? '请选择' + item.title"
                    />
                    <el-date-picker
                        v-if="item.type === 'dateRange'"
                        v-model="data[item.field]"
                        type="daterange"
                        :range-separator="item.rangeSeparator ?? '至'"
                        :start-placeholder="item.startPlaceholder ?? '开始日期'"
                        :end-placeholder="item.endPlaceholder ?? '结束日期'"
                        :size="item.size"
                        :clearable="item.clearable ?? true"
                    />
                    <el-date-picker
                        v-if="item.type === 'datetimeRange'"
                        v-model="data[item.field]"
                        type="datetimerange"
                        :range-separator="item.rangeSeparator ?? '至'"
                        :start-placeholder="item.startPlaceholder ?? '开始日期'"
                        :end-placeholder="item.endPlaceholder ?? '结束日期'"
                        :size="item.size"
                        :clearable="item.clearable ?? true"
                    />
                    <el-cascader
                        v-if="item.type === 'cascader'"
                        v-model="data[item.field]"
                        :options="item.options ?? []"
                        :clearable="item.clearable ?? true"
                        :placeholder="item.placeholder ?? '请选择' + item.title"
                        :size="item.size"
                        :collapse-tags="true"
                        :collapse-tags-tooltip="true"
                        :show-all-levels="item.showAllLevels ?? false"
                        :props="{
                            multiple: item.multiple ?? true,
                            expandTrigger: item.expandTrigger ?? 'click',
                            value: item.valueKey ?? 'value',
                            label: item.labelKey ?? 'label',
                            children: item.children ?? 'children',
                            emitPath: item.emitPath ?? true
                        }"
                        :validate-event="false"
                    />
                    <cbz-upload
                        v-if="item.type === 'upload'"
                        v-model="data[item.field]"
                        text="缩略图上传"
                    />
                    <div v-if="item.type === 'richText'" style="width: 100%;border: 1px solid #ccc">
                        <Toolbar
                            style="border-bottom: 1px solid #ccc"
                            :editor="editorRef"
                            :defaultConfig="toolbarConfig"
                            mode="default"
                        />
                        <Editor
                            style="height: 500px; overflow-y: hidden;"
                            v-model="data[item.field]"
                            :defaultConfig="editorConfig"
                            mode="default"
                            @onCreated="handleCreated"
                        />
                    </div>
                </el-form-item>
            </template>
        </el-form>
        <div class="submit" :class="config.button?.class ?? ''" @click="submit">
            <el-button
                :loading="loading"
                :type="config.button?.type ?? 'primary'"
                :size="config.button?.size ?? 'default'"
                :plain="config.button?.plain ?? false"
                :round="config.button?.round ?? false"
                :circle="config.button?.circle ?? false"
            >{{config.button.text}}</el-button>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>