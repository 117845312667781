<script setup>
import {useStore} from "vuex";
import axios from "@/utils/axios";
const store = useStore()
//刷新前保存用户凭证
window.addEventListener("beforeunload",()=>{
    if (store.state.login) {
        sessionStorage.setItem('accessToken', store.state.accessToken)
        sessionStorage.setItem('refreshToken', store.state.refreshToken)
    }
})

</script>
<template>
    <router-view/>
</template>
<style lang="scss">
html,body {
    padding: 0;
    margin: 0;
}
:focus-visible {
    outline: 0;
}

a {
    text-decoration: none;
    color: unset;
}

::-webkit-scrollbar {
    width: 0;
}

.cbz-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 18px;
    color: #333;
    margin-bottom: 18px;
    padding-left: 16px;
    &::before {
        content: '';
        display: block;
        width: 4px;
        height: 20px;
        border-radius: 4px;
        background-color: #409EFF;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.cbz-title+.cbz-title {
    margin-top: 10px;
}

.el-select, .el-date-editor, .el-cascader {
    width: 100%!important;
}

.cbz-card-loading {
    height: 120px;
}

.cbz-table {
    --header-bg-color: #F2F6FC;
    .cbz-table-header {
        background-color: var(--header-bg-color);
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cbz-table-header-left, .cbz-table-header-right {
            display: flex;
            align-items: center;
        }
    }
    .cbz-table-body {
        .el-table {
            --el-table-header-bg-color: var(--header-bg-color);
        }
    }
    .cbz-table-footer {
        background-color: var(--header-bg-color);
        padding: 10px;
    }
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.el-select {
    width: 100%;
}

:deep(.el-table-column--selection>.cell) {
    justify-content: center
}

:deep(.cbz-form-btn ) {
    text-align: right;
    margin-top: 64px;
}


.detail-dialog {
    min-height: calc(100vh - 160px);
    margin-left: 240px!important;
}

.confirm-dialog {
    margin-left: 240px!important;
}
</style>
