<script setup>
import {computed, reactive, ref} from "vue";
import axios from "@/utils/axios";

const props = defineProps({
    modelValue: {
        type: String
    },
    placeholder: {
        type: String,
        default: '请输入验证码'
    },
    clearable: {
        type: Boolean,
        default: true
    },
    prefixIcon: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['update:modelValue'])
const data = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})
const captcha = reactive({
    img: '',
    key: '',
});
const loading = ref(false)
const error = ref(false)
const refresh = () => {
    loading.value = true
    axios.request({
        url: 'admin/captcha'
    }).then(res => {
        loading.value = false
        error.value = false
        data.value = ''
        captcha.img = res.img
        captcha.key = res.key
    }).catch(() => {
        loading.value = false
        error.value = true
    })
}
refresh()
const getKey = () => {
    return captcha.key
}
defineExpose({refresh, getKey})
</script>

<template>
    <el-input
        v-model="data"
        :placeholder="props.placeholder"
        :clearable="props.clearable"
        :prefix-icon="props.prefixIcon">
        <template #append>
            <div class="captcha" v-loading="loading" @click="refresh">
                <div class="error" v-if="error">加载出错，点击重试</div>
                <img v-else :src="captcha.img" alt="captcha.jpg">
            </div>
        </template>
    </el-input>
</template>

<style scoped lang="scss">
    .captcha {
        width: 140px;
        text-align: center;
        font-size: 12px;
        img {
            display: block;
            height: 38px;
        }
        .error {
            cursor: pointer;
        }
    }
</style>